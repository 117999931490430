<template>
  <div>
    <div v-if="pChip" class="chip-container" :class="customClass">
      <v-tooltip open-delay="500" bottom :disabled="pChip.reports.length === 1">
        <template v-slot:activator="{ on, attrs }">
          <v-progress-circular
            v-if="pChip.inProcess"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-chip
            v-bind="attrs"
            v-on="on"
            :active="!pChip.inProcess"
            :color="pChip.color"
            dark
            center-active
            class="active-chip"
            @click="applyChipAction()"
          >
            {{ displayText }}
          </v-chip>
        </template>
        <div>
          <p class="mb-0">
            {{
            $localizationService.localize(
              'report_calendar.chip_info.reports'
            )
            }}
            : {{ pChip.reports.length }}
          </p>
          <p class="mb-0">
          <span class="green--text">{{
            $localizationService.localize(
              'report_calendar.chip_info.parsed'
            )
            }}
          :</span> {{ countOfParsed }}
          </p>
          <p class="mb-0">
          <span class="orange--text">{{
            $localizationService.localize(
              'report_calendar.chip_info.mar'
            )
            }}
          :</span> {{ countOfMar }}
          </p>
          <p class="mb-0">
          <span class="red--text">{{
            $localizationService.localize(
              'report_calendar.chip_info.error'
            )
            }}:</span>  {{ countOfError }}
          </p>
        </div>
      </v-tooltip>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <v-btn
        outlined
        color="primary"
        class="active-chip button-hover"
      ><v-icon>mdi-plus</v-icon>
        <input
          @input="uploadReport($event)"
          type="file"
          name="upload"
          class="file-input"
          multiple
          accept=".pdf, .csv, .xlsx"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

const locService = Vue.prototype.$localizationService;

export default {
  name: "CalendarChip",

  props: {
    pChip: Object,
    pTargetDate: String,
    pLastDateInCalendar: String,
  },

  computed: {
    displayStatus() {
      const targetDateMoment = moment(this.pTargetDate);

      return targetDateMoment.isSame(this.pChip.endDate) || targetDateMoment.isSame(this.pLastDateInCalendar)
        ? this.pChip.status.text
        : "  ";
    },

    customClass() {
      if (this.pChip.startDate === this.pChip.endDate) {
        return undefined;
      }

      const targetDateMoment = moment(this.pTargetDate);

      if (targetDateMoment.isSame(this.pChip.startDate)) {
        return "chip-row-start";
      }

      if (targetDateMoment.isSame(this.pChip.endDate)) {
        return "chip-row-end";
      }

      return "chip-row-middle";
    },

    displayText() {
      if (this.pChip.reports.length === 1) {
        return this.displayStatus;
      }

      return this.pChip.hasError
        ? this.displayStatus
        : locService.localize("report_calendar.chip_info.reports_count", [this.pChip.reports.length]);
    },

    countOfParsed() {
      return this.pChip.reports.filter((it) => it.isParsed).length;
    },

    countOfMar() {
      return this.pChip.reports.filter((it) => it.isMar).length;
    },

    countOfError() {
      return this.pChip.reports.filter((it) => it.isError).length;
    },
  },

  methods: {
    applyChipAction() {
      if (!this.pChip.hasAction) return;

      const targetReport = this.pChip.reports.find((it) => it.isMar || it.isError);
      this.$emit("onOpenActionDialog", targetReport);
      if (this.pChip.reports.length > 1) {
        this.saveChipReportsForPendingActions(targetReport);
      }
    },

    saveChipReportsForPendingActions(targetReport) {
      const pendingReports = this.pChip.reports.filter((report) => (
        report.id !== targetReport.id && (report.isMar || report.isError)
      ));

      if (pendingReports.length) {
        this.updatePendingReports(pendingReports);
      } else {
        this.updatePendingReports([]);
      }
    },

    uploadReport(event) {
      if (event.target.files.length) {
        this.$emit("onUploadReport", event);
      }
    },

    updatePendingReports(data) {
      this.$store.commit("reportModule/updatePendingReports", data);
    },
  },
};
</script>

<style scoped>

</style>
